$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';

.deployments-datatable.datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.p-dialog-content {
  .p-field {
    overflow: visible !important;
  }

  .p-autocomplete .p-autocomplete-panel {
    min-width: 100%;
    max-width: 100%;
  }
}

.p-component-overlay-leave {
  display: none;
}

.location-edit-dialog .p-dialog-content {
  overflow: visible !important;
}

.layout-topbar-app-context {
  display: inline-flex;
  margin-left: 100px;
  align-items: center;
  height: 45px;
  vertical-align: middle;

  .layout-topbar-label {
    color: white;
    margin-bottom: 4px;
  }

  .layout-topbar-name {
    color: white;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .layout-topbar-company {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -2px;
  }

  .layout-topbar-location {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -2px;
    margin-left: 1em;
  }
}

.noExpander .p-row-toggler {
  display: none;
}

@media screen and (max-width: 896px) {
  .layout-topbar {
    display: flex;
  }
  .layout-topbar-app-context {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .layout-topbar-name {
      margin-bottom: 0;
      margin-right: 4px;
    }

    .layout-topbar-company {
      flex-direction: row;
    }

    .layout-topbar-location {
      flex-direction: row;
    }

    .p-dropdown {
      height: 17px;

      .p-dropdown-label {
        font-size: 12px;
        line-height: 2px;
      }
    }
  }
}
